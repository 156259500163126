var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app', [_c('ConfirmDialog'), _c('Snack'), _c('v-main', [_c('v-container', {
    attrs: {
      "fill-height": ""
    }
  }, [_c('v-row', {
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "8",
      "md": "4"
    }
  }, [_c('v-card', {
    staticClass: "ma-2 pa-4 elevation-6"
  }, [_c('v-card-title', {
    staticClass: "justify-center"
  }, [_c('v-img', {
    staticClass: "shrink mr-2",
    attrs: {
      "contain": "",
      "src": "/Logo_DC-Plaisance_couleur.PNG",
      "transition": "scale-transition",
      "max-width": "150"
    }
  })], 1), _c('v-card-text', [_c('v-form', [_c('v-text-field', {
    attrs: {
      "label": _vm.$t('login.email'),
      "prepend-icon": "mdi-account",
      "type": "text",
      "autocapitalize": "off",
      "autocomplete": "username"
    },
    on: {
      "keyup": _vm.keyboardValidate
    },
    model: {
      value: _vm.username,
      callback: function callback($$v) {
        _vm.username = $$v;
      },
      expression: "username"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": _vm.$t('login.mot-de-passe'),
      "prepend-icon": "mdi-lock",
      "append-icon": _vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',
      "type": _vm.showPassword ? 'text' : 'password',
      "autocomplete": "current-password"
    },
    on: {
      "keyup": _vm.keyboardValidate,
      "click:append": function clickAppend($event) {
        _vm.showPassword = !_vm.showPassword;
      }
    },
    model: {
      value: _vm.password,
      callback: function callback($$v) {
        _vm.password = $$v;
      },
      expression: "password"
    }
  })], 1)], 1), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "block": "",
      "color": "primary"
    },
    on: {
      "click": _vm.connect
    }
  }, [_vm._v(_vm._s(_vm.$t('login.se-connecter')))])], 1), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "text": "",
      "small": "",
      "block": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.askResetPassword();
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('login.mot-de-passe-oublie')))])], 1)], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }