




















































import { Component, Vue, Watch } from "vue-property-decorator";

import ConfirmDialog from "@/components/ConfirmDialog.vue";
import Snack from "@/components/Snack.vue";

import router, { routeToMap } from "@/router/router";
import restService from "@/services/restService";

@Component({
  components: {
    ConfirmDialog,
    Snack,
  },
})
export default class Login extends Vue {
  showPassword: boolean = false;
  username: string = "";
  password: string = "";
  path: string | null = null;

  @Watch("$route")
  routeChanged(): void {
    this.path = this.$route.query.path as string | null;
  }

  async askResetPassword(): Promise<void> {
    if (! this.username) {
      this.errorNotify(this.$t('login.veuillez-saisir-votre-email'), new Error("email"));
      return;
    }

    await this.showConfirmDialog(
      this.$t('login.reinitiliser-votre-mot-de-passe'),
      this.$t('login.un-email-vous-sera-envoye-a') + this.username,
      null,
      this.$t('login.reinitiliser'),
      this.$t('login.annuler')
    );
    if (!confirm) {
      return;
    }

    try {
      await restService.requestResetPassword(this.username);
      this.successNotify(this.$t('login.votre-demande-a-ete-prise-en-compte'))
    } catch (error) {
      this.errorNotify(this.$t('login.votre-demande-na-pas-pu-etre-prise-en-comtpe'), error)
    }
  }

  async connect(): Promise<void> {
    try {
      await restService.obtainToken(this.username, this.password);
      //       get customerId(): number | undefined {
      //   const token: Token | null = this.decodeToken(this.jwtState.jwtAccess);
      //   if (!token) {
      //     return undefined;
      //   }

      //   return (token as unknown as {customer_id: number | undefined}).customer_id;
      // }
      restService.setUsername(this.username);
      // refreshUser();
      // await usersStore.refresh();
      // if (usersStore.state.customer) {
        if (this.path) {
          console.log("this.path", this.path);
          router.push(this.path);
        } else {
          console.log("routeToMap");
          routeToMap();
        }
      // }
    } catch (error) {
      this.errorNotify(this.$t('login.identifiant-ou-mdp-errone'), error as Error);
    }
  }

  keyboardValidate(e: KeyboardEvent): void {
    if (["NumpadEnter", "Enter"].includes(e.key)) {
      this.connect();
    }
  }

  mounted(): void {
    this.path = this.$route.query.path as string | null;
    let username: string | null = this.$route.query.username as string | null;

    if (username) {
      this.username = username;
      return;
    }
    
    username = restService.getUsername();
    if (username) {
      this.username = username;
    }
  }
}
